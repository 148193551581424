import React, {Suspense, useLayoutEffect} from 'react'
import {BrowserRouter as Router, Route, Switch, withRouter} from 'react-router-dom'

// Kontroll Labor
// Price list
const PriceList = React.lazy(() => import('./components/pages/PriceList'))
// Koronavírus
const CovidFaq = React.lazy(() => import('./components/pages/CovidFaqs'))
const CovidPcr = React.lazy(() => import('./components/pages/CovidPcr'))
const CovidPrices = React.lazy(() => import('./components/pages/CovidPrices'))
// Packages
const Packages = React.lazy(() => import('./components/pages/Packages'))
// Csomagok
const Covid = React.lazy(() => import('./components/pages/packages/Covid'))
const SportDiagnosztika = React.lazy(() => import('./components/pages/packages/SportDiagnosztika'))
const SzemyelreSzabott = React.lazy(() => import('./components/pages/packages/SzemyelreSzabott'))
const EmesztorendszerTaplalkozas = React.lazy(() => import('./components/pages/packages/EmesztorendszerTaplalkozas'))
const Szurovizsgalatok = React.lazy(() => import('./components/pages/packages/Szurovizsgalatok'))
const CsaladtervezesVarandossag = React.lazy(() => import('./components/pages/packages/CsaladtervezesVarandossag'))
const GlukozhaztartasElhizas = React.lazy(() => import('./components/pages/packages/GlukozhaztartasElhizas'))
const VeralvadasVerkepzes = React.lazy(() => import('./components/pages/packages/VeralvadasVerkepzes'))
const EndorkrinologiaHormonok = React.lazy(() => import('./components/pages/packages/EndorkrinologiaHormonok'))
const Allergia = React.lazy(() => import('./components/pages/packages/Allergia'))
const Tumormarkerek = React.lazy(() => import('./components/pages/packages/Tumormarkerek'))
const ApasagiDnsVizsgalatok = React.lazy(() => import('./components/pages/packages/ApasagiDnsVizsgalatok'))
// Csomagok SubPages
const CovidPajzsAlapcsomag = React.lazy(() => import('./components/pages/packages/covid/CovidPajzsAlapcsomag'))

// Home
const Home = React.lazy(() => import('./components/pages/Home'))
const Hometwo = React.lazy(() => import('./components/pages/Hometwo'))
// Blog
const Blog = React.lazy(() => import('./components/pages/Blog'))
const Blogstandard = React.lazy(() => import('./components/pages/Blogstandard'))
const Blogdetails = React.lazy(() => import('./components/pages/Blogdetails'))
// About
const About = React.lazy(() => import('./components/pages/About'))
// Services
const Services = React.lazy(() => import('./components/pages/Services'))
const Servicedetails = React.lazy(() => import('./components/pages/Servicedetails'))
// FAQ's
const Faqs = React.lazy(() => import('./components/pages/Faqs'))
// Appointment
const Appointment = React.lazy(() => import('./components/pages/Appointment'))
// Clinics
const Clinicgrid = React.lazy(() => import('./components/pages/Clinicgrid'))
const Cliniclist = React.lazy(() => import('./components/pages/Cliniclist'))
const Clinicdetails = React.lazy(() => import('./components/pages/Clinicdetails'))
// Doctors
const Doctorgrid = React.lazy(() => import('./components/pages/Doctorgrid'))
const Doctorlist = React.lazy(() => import('./components/pages/Doctorlist'))
const Doctordetails = React.lazy(() => import('./components/pages/Doctordetails'))
// Contact
const Contact = React.lazy(() => import('./components/pages/Contact'))
// Extra
const Errorpage = React.lazy(() => import('./components/pages/Errorpage'))

// Scroll to Top
const ScrollToTop = withRouter(({children, location: {pathname}}) => {
  useLayoutEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])

  return children || null
})

function App() {
  return (
    <Router basename={'/'}>
      <Suspense fallback={<div></div>}>
        <ScrollToTop>
          <Switch>
            {/* Custom Kontroll Labor Routes */}
            {/* Price List */}
            <Route exact path="/price" component={PriceList} />
            {/* Packages */}
            <Route exact path="/csomagok" component={Packages} />
            {/* Csomagok */}
            <Route exact path="/csomagok/covid" component={Covid} />
            <Route exact path="/csomagok/sportdiagnosztika" component={SportDiagnosztika} />
            <Route exact path="/csomagok/szemelyre-szabott" component={SzemyelreSzabott} />
            <Route exact path="/csomagok/emesztorendszer-taplalkozas" component={EmesztorendszerTaplalkozas} />
            <Route exact path="/csomagok/szurovizsgalatok" component={Szurovizsgalatok} />
            <Route exact path="/csomagok/csaladtervezes-varandossag" component={CsaladtervezesVarandossag} />
            <Route exact path="/csomagok/glukozhaztartas-elhizas" component={GlukozhaztartasElhizas} />
            <Route exact path="/csomagok/veralvadas-verkepzes" component={VeralvadasVerkepzes} />
            <Route exact path="/csomagok/endokrinologia-hormonok" component={EndorkrinologiaHormonok} />
            <Route exact path="/csomagok/allergia" component={Allergia} />
            <Route exact path="/csomagok/tumormarkerek" component={Tumormarkerek} />
            <Route exact path="/csomagok/apasagi-dns-vizsgalatok" component={ApasagiDnsVizsgalatok} />
            {/* Csomagok SubPages */}
            <Route exact path="/csomagok/covid/covid-pajzs-alapcsomag" component={CovidPajzsAlapcsomag} />

            {/* Koronavírus */}
            <Route exact path="/covid/faqs" component={CovidFaq} />
            <Route exact path="/covid/pcr" component={CovidPcr} />
            <Route exact path="/covid/prices" component={CovidPrices} />

            {/* Home */}
            <Route exact path="/" component={Home} />
            <Route exact path="/home-v2" component={Hometwo} />
            {/* Blog */}
            <Route exact path="/blog/cat/:catId" component={(props) => <Blog {...props} key={window.location.pathname} />} />
            <Route exact path="/blog/tag/:tagId" component={(props) => <Blog {...props} key={window.location.pathname} />} />
            <Route exact path="/blog/search/:query" component={(props) => <Blog {...props} key={window.location.pathname} />} />
            <Route exact path="/blog/author/:authorId" component={(props) => <Blog {...props} key={window.location.pathname} />} />
            <Route exact path="/blog" component={Blog} />
            <Route exact path="/blog-standard" component={Blogstandard} />
            <Route exact path="/blog-details/:id" component={(props) => <Blogdetails {...props} key={window.location.pathname} />} />
            {/* About */}
            <Route exact path="/about" component={About} />
            {/* Services */}
            <Route exact path="/service/cat/:catId" component={(props) => <Services {...props} key={window.location.pathname} />} />
            <Route exact path="/services" component={Services} />
            <Route exact path="/service-details/:id" component={(props) => <Servicedetails {...props} key={window.location.pathname} />} />
            {/* FAQ's */}
            <Route exact path="/faqs" component={Faqs} />
            {/* Appointment */}
            <Route exact path="/appointment" component={Appointment} />
            {/* Clinics */}
            <Route exact path="/clinic/cat/:catId" component={(props) => <Clinicgrid {...props} key={window.location.pathname} />} />
            <Route exact path="/clinic-grid" component={Clinicgrid} />
            <Route exact path="/clinic-list" component={Cliniclist} />
            <Route exact path="/clinic-details/:id" component={(props) => <Clinicdetails {...props} key={window.location.pathname} />} />
            {/* Doctors */}
            <Route exact path="/doctor/cat/:catId" component={(props) => <Doctorgrid {...props} key={window.location.pathname} />} />
            <Route exact path="/doctor-grid" component={Doctorgrid} />
            <Route exact path="/doctor-list" component={Doctorlist} />
            <Route exact path="/doctor-details/:id" component={(props) => <Doctordetails {...props} key={window.location.pathname} />} />
            {/* Contact */}
            <Route exact path="/contact" component={Contact} />
            {/* Extra */}
            <Route exact path="/error-page" component={Errorpage} />
            <Route exact component={Errorpage} />
          </Switch>
        </ScrollToTop>
      </Suspense>
    </Router>
  )
}

export default App
